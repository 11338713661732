export const classifiedsSearchAdvertsCustom = /* GraphQL */ `
  query ClassifiedsSearchAdvertsCustom(
    $filter: AdvertSearchInput
    $from: String
    $sort: NearbyAdvertsSortInput
    $limit: Int
  ) {
    classifiedsSearchAdverts(filter: $filter, from: $from, sort: $sort, limit: $limit) {
      items {
        id
        age
        slug
        currency
        breed
        colourId
        featuredAt
        categoryId
        colour {
          id
          label
          updatedAt
          createdAt
        }
        coords {
          lat
          lon
        }
        description
        formattedLocation
        genderId
        gender {
          id
          label
          updatedAt
          createdAt
        }
        headline
        height
        isFree
        isPOA
        media {
          id
          fileName
          width
          height
          type
        }
        youTubeVideos {
          id
          title
          description
        }
        price
        saleTypeId
        saleType {
          id
          label
          updatedAt
          createdAt
        }
        propertySaleTypeId
        trailerSaleTypeId
        status
        syncedAt
        typeId
        type {
          id
          label
          updatedAt
          createdAt
        }
        createdAt
        updatedAt
        deletedAt
        owner
        userSuspended
        soldAt
        horseAndHoundMagazine
        weight
        sleeps
        payload
        condition
        numberOfHorses
        modelName
        manufacturer
        propertyTypeId
        numberOfBedrooms
        numberOfBathrooms
        numberOfReceptionRooms
        numberOfStables
        facilities
        living
        affiliatedCompetitionRecord
        annex
        numberOfPaddocks
        salary
        accommodation
        petsAllowed
        horsesAllowed
        ftpt
        stablesTypeId
        priceTypeId
        stallionsName
        studFeeIncludesVat
        studBookOfOrigin
        graded
        gradedWith
        availability
        concessions
        studPrice {
          fresh {
            amount
            currency
          }
          chilled {
            amount
            currency
          }
          frozen {
            amount
            currency
          }
          natural {
            amount
            currency
          }
        }
        dam
        damGrandDam
        damGrandDamGreatGrandDam
        damGrandDamGreatGrandSire
        damGrandSire
        damGrandSireGreatGrandDam
        damGrandSireGreatGrandSire
        sire
        sireGrandDam
        sireGrandDamGreatGrandDam
        sireGrandDamGreatGrandSire
        sireGrandSire
        sireGrandSireGreatGrandDam
        sireGrandSireGreatGrandSire
        seller {
          id
          owner
          bio
          firstName
          lastName
          isBusiness
          formattedLocation
          businessName
          img
          createdAt
          updatedAt
          syncedAt
        }
      }
      from
      total
    }
  }
`;
