import qs from 'qs';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Empty, Spin, Row, Col } from 'antd';
import { getAdverts } from '../../methods/adverts';
import { getSearchOptions } from '../../utils/filterTypes';
import store from '../../config/store';
import { addAdverts, updateAdverts } from '../../actions/advertActions';
import AdvertList from '../../providers/AdvertList';
import WidgetAdvertCard from '../../components/WidgetAdvertCard';
import device from '../../utils/device';
import { storage } from '../../utils';
import getAmazonUrl from '../../methods/getAmazonUrl';
import './styles.less';

const Widget = (props) => {
  const location = useLocation();
  const history = useHistory();

  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(true);

  let targetFilters = {};

  if (location.search) {
    targetFilters = location.search;
  }
  const searchParamsInUrl = qs.parse(targetFilters, { ignoreQueryPrefix: true });

  const searchParamsDataValues = getSearchOptions(searchParamsInUrl);

  const [searchParams, setSearchParams] = useState(searchParamsDataValues.api);

  async function fetchAdverts(searchParams) {
    setLoading(true);

    storage.removeItem(`filters`);
    const advertItems = await getAdverts({ ...searchParams, limit: 4 });
    if (advertItems) {
      store.store.dispatch(updateAdverts(advertItems));
    }
    setLoading(false);
  }

  function updateUrl(items, shouldClear = false) {
    let searchParamsInUrlSnapshot = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    });

    if (shouldClear) {
      searchParamsInUrlSnapshot = {
        p: searchParamsInUrlSnapshot.p,
      };
    }

    for (const key in items) {
      searchParamsInUrlSnapshot[key] = items[key];
    }

    if (!searchParamsInUrlSnapshot.searchLocation) {
      delete searchParamsInUrlSnapshot.miles;
    }

    const stringifiedSearchParams = qs.stringify(searchParamsInUrlSnapshot, {
      indices: false,
      allowDots: true,
      addQueryPrefix: true,
    });

    history.push({
      pathname: location.pathname,
      search: stringifiedSearchParams,
    });
  }
  const typeId = props.match.params.widgetTypeId;

  useEffect(() => {
    const startAdverts = async () => {
      setHasMore(false);

      setLoading(true);

      const searchParamsInUrlSnapshot = qs.parse(location.search, {
        ignoreQueryPrefix: true,
      });

      const { urlParameters, apiQuery } = getSearchOptions(searchParamsInUrlSnapshot);

      updateUrl(urlParameters, true);
      await fetchAdverts(apiQuery);
      setLoading(false);

      // console.log('searchParams', searchParams);
      // const advertItems = await getAdverts({
      //   ...searchParams,
      //   from: 0,
      // });
      // if (advertItems && advertItems.items.length > 0) {
      //   store.store.dispatch(addAdverts(advertItems));
      //   setHasMore(true);
      // }
      setLoading(false);
    };
    startAdverts();

    const resizeFunction = () => {
      if (typeId === '0') {
        const heightToSetTo = document.getElementById('root')?.clientHeight - 21;
        window.parent.window.postMessage(
          {
            height: heightToSetTo,
          },
          'https://www.horseandhound.co.uk'
        );

        document.getElementsByClassName('widgetCardContainer')[0].style.height =
          heightToSetTo;
        document.getElementsByClassName('typeZero')[0].style.height = heightToSetTo;
      }
      if (typeId === '2') {
        const heightToSetTo = document.getElementById('root')?.clientHeight;
        window.parent.window.postMessage(
          {
            height: heightToSetTo,
          },
          'https://www.horseandhound.co.uk'
        );
      }
    };
    window?.addEventListener('resize', resizeFunction);
    resizeFunction();
    return () => {
      window?.removeEventListener('resize', resizeFunction);
    };
  }, []);

  return (
    <div className={typeId === '0' ? 'typeZero' : 'typeTwo'}>
      <div className="widgetCardContainer">
        <AdvertList searchParams={searchParams}>
          {({ adverts, from }) => {
            return (
              <>
                {!loading && adverts.length === 0 && (
                  <div style={{ marginTop: 60 }}>
                    <Empty
                      image="/img/no-adverts.svg"
                      imageStyle={{
                        height: 40,
                        marginBottom: 16,
                      }}
                      description={
                        <span className="emptyMessage">
                          <h2>No Adverts</h2>
                          <p>Try expanding your search!</p>
                        </span>
                      }
                    />
                  </div>
                )}
                {adverts?.length > 0 &&
                  adverts.map(
                    ({
                      id,
                      price,
                      age,
                      headline,
                      height,
                      formattedLocation,
                      seller,
                      favouriteId,
                      soldAt,
                      isPOA,
                      categoryId,
                      isFree,
                      slug,
                      media,
                      youTubeVideos,
                      ...advert
                    }) => {
                      const sellerId = seller?.id;
                      const firstName = seller?.firstName;
                      const lastName = seller?.lastName;
                      const isBusiness = seller?.isBusiness;
                      const businessName = seller?.businessName;

                      const gender = advert.gender?.label;
                      const type = advert.type?.label;
                      const saleType = advert.saleType?.label;
                      // if (advert.categoryId === 'trailer') {
                      //   saleType = capitalizeFirstLetter(advert.trailerSaleTypeId);
                      // }

                      return (
                        <div className="widgetCard">
                          <WidgetAdvertCard
                            inIframe
                            price={price}
                            categoryId={categoryId}
                            slug={slug}
                            title={headline}
                            sellerName={
                              isBusiness ? businessName : `${firstName} ${lastName}`
                            }
                            previewMedia={
                              media && media.length > 0
                                ? {
                                    ...media[0],
                                    url: `${getAmazonUrl(media[0].id)}${
                                      media[0].fileName
                                    }`,
                                  }
                                : youTubeVideos &&
                                  youTubeVideos.length > 0 &&
                                  youTubeVideos[0]
                                ? { ...youTubeVideos[0], type: 'youtube' }
                                : null
                            }
                            sellerLocation={formattedLocation}
                            sellerId={sellerId}
                            gender={gender}
                            height={height}
                            isPOA={isPOA}
                            isFree={isFree}
                            age={age}
                            saleType={saleType}
                            type={type}
                            advertId={id}
                            favouriteId={favouriteId}
                            isSold={!!soldAt}
                            widgetTypeId={typeId}
                          />
                        </div>
                      );
                    }
                  )}

                {loading && (
                  <>
                    <div className="widgetCard" style={{ visibility: 'hidden' }}>
                      <WidgetAdvertCard
                        inIframe
                        price="£"
                        age="0"
                        categoryId="horse"
                        height={0}
                        gender="Gender details Loading"
                        title="Advert headline currently loading ..."
                        sellerName="Seller name loading"
                        widgetTypeId={typeId}
                      />
                    </div>
                    <div className="widgetCard" style={{ visibility: 'hidden' }}>
                      <WidgetAdvertCard
                        inIframe
                        price="£"
                        age="0"
                        categoryId="horse"
                        height={0}
                        gender="Gender details Loading"
                        title="Advert headline currently loading ..."
                        sellerName="Seller name loading"
                        widgetTypeId={typeId}
                      />
                    </div>
                    <div className="widgetCard" style={{ visibility: 'hidden' }}>
                      <WidgetAdvertCard
                        inIframe
                        price="£"
                        age="0"
                        categoryId="horse"
                        height={0}
                        gender="Gender details Loading"
                        title="Advert headline currently loading ..."
                        sellerName="Seller name loading"
                        widgetTypeId={typeId}
                      />
                    </div>
                    <div className="widgetCard" style={{ visibility: 'hidden' }}>
                      <WidgetAdvertCard
                        inIframe
                        price="£"
                        age="0"
                        categoryId="horse"
                        height={0}
                        gender="Gender details Loading"
                        title="Advert headline currently loading ..."
                        sellerName="Seller name loading"
                        widgetTypeId={typeId}
                      />
                    </div>
                    <div
                      style={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: 0,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <Spin size="large" />
                    </div>
                  </>
                )}
              </>
            );
          }}
        </AdvertList>
      </div>
    </div>
  );
};

export default Widget;
