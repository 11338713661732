import React, { ReactElement, useState, useEffect, useRef } from 'react';
import {
  Row,
  Col,
  Spin,
  Typography,
  Empty,
  message,
  Collapse,
  Button,
  Space,
  Pagination,
} from 'antd';
import qs from 'qs';
import { useHistory, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';

import { withSSRContext } from 'aws-amplify';

import { ArrowRightOutlined } from '@ant-design/icons';
import AdvertCard from '../../components/AdvertCard';
import FilterBox from '../../components/FilterBox';
import { getAdverts } from '../../methods/adverts';
import { Advert, Profile } from '../../models';
import getAmazonUrl from '../../methods/getAmazonUrl';

import AdvertList from '../../providers/AdvertList';

import { addAdverts, updateAdverts } from '../../actions/advertActions';
import store from '../../config/store';
import { storage } from '../../utils';

import './styles.less';
import Head from '../../components/Head';
import device from '../../utils/device';
import { getSearchOptions, rangeTypes, termTypes } from '../../utils/filterTypes';
import { parseValue } from '../../utils/parseing';
import LocationSearchInput from '../../components/PlacesAutoComplete/PlacesAutoComplete';
import { categoryIdByPath } from '../../utils/categoryPaths';
import { updateDisciplines } from '../../actions/miscActions';
import getDisciplines from '../../methods/getDisciplines';

const { REACT_APP_CLASSIFIEDS_URL, REACT_APP_WHICKR_URL } = process.env;

const { Panel } = Collapse;

const messageKey = 'updatable';

type Props = {
  staticAdverts: any[];
  user: Profile;
};

function capitalizeFirstLetter(string) {
  if (string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
}

const BrowseScene: React.FC = ({
  staticAdverts,
  user,
  disciplines,
}: Props): ReactElement => {
  const history = useHistory();
  const location = useLocation();
  const [filtersCollapsed, setFiltersCollapsed] = useState(true);

  const [hasMore, setHasMore] = useState(true);
  const pageLoaded = useRef(false);

  const locationSearch = useRef(location.search);
  const locationPathName = useRef(location.pathname);

  const [loading, setLoading] = useState(true);
  const categoryId = categoryIdByPath[location.pathname] || 'horse';

  const categoryIdRef = useRef(categoryId);
  const firstLoad = useRef(true);

  const categoryTitle = {
    horse: 'Horses',
    trailer: 'Horseboxes & Trailers',
    job: 'Equestrian Jobs',
    property: 'Equestrian Properties',
    dog: 'Dogs',
    cat: 'Cats',
    // stallionsAtStud: 'Stallions at Stud',
    // liveryYard: 'Livery Yards',
    // misc: 'Miscellaneous',
  };

  const SALE = '80b50ee5-0f84-48e7-92f1-65793a48d597';
  const LOAN = '89ac3e31-fcfb-4794-8af9-6342f2d2b56e';
  let targetFilters = {};

  if (location.search) {
    targetFilters = location.search;
    storage.setItem(`filters`, location.search);
  }

  const searchParamsInUrl = qs.parse(targetFilters, { ignoreQueryPrefix: true });

  if (['/horses-for-sale', '/horses-and-ponies-for-sale'].includes(location.pathname)) {
    searchParamsInUrl.saleTypeId = SALE;
  }

  if (['/horses-for-loan-share'].includes(location.pathname)) {
    searchParamsInUrl.saleTypeId = LOAN;
  }

  // const searchParamsDataValues = getSearchOptions(searchParamsInUrl);

  const { filterBoxProps } = getSearchOptions(searchParamsInUrl);

  function updateUrl(items, shouldClear = false) {
    let { pathname } = location;
    let searchParamsInUrlSnapshot = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    });

    if (shouldClear) {
      searchParamsInUrlSnapshot = {
        p: searchParamsInUrlSnapshot.p,
      };
    }

    for (const key in items) {
      searchParamsInUrlSnapshot[key] = items[key];
    }

    // if (['/horses-for-sale'].includes(location.pathname)) {
    //   searchParamsInUrlSnapshot.saleTypeId = SALE;
    // }

    // if (['/horses-for-loan-share'].includes(location.pathname)) {
    //   searchParamsInUrlSnapshot.saleTypeId = LOAN;
    // }

    if (
      searchParamsInUrlSnapshot.saleTypeId === SALE &&
      !['/horses-for-sale', '/horses-and-ponies-for-sale'].includes(location.pathname)
    ) {
      pathname = '/horses-for-sale';
    }

    if (
      searchParamsInUrlSnapshot.saleTypeId === LOAN &&
      !['/horses-for-loan-share'].includes(location.pathname)
    ) {
      pathname = '/horses-for-loan-share';
    }

    if (!searchParamsInUrlSnapshot.searchLocation) {
      delete searchParamsInUrlSnapshot.miles;
    }

    const stringifiedSearchParams = qs.stringify(searchParamsInUrlSnapshot, {
      indices: false,
      allowDots: true,
      addQueryPrefix: true,
    });

    storage.setItem(`filters`, stringifiedSearchParams);

    history.push({
      pathname,
      search: stringifiedSearchParams,
    });
  }

  async function resetAdverts(searchParams, messageItem?: string) {
    setLoading(true);
    searchParams.from = 0;
    storage.removeItem(`filters`);
    const advertItems = await getAdverts(searchParams);
    if (advertItems) {
      store.store.dispatch(updateAdverts(advertItems));

      window.scrollTo({ top: 0 });
      if (advertItems?.items?.length > 0) {
        setHasMore(true);
      }
    }
    setLoading(false);
    if (messageItem) {
      message.success({ content: messageItem, key: messageKey, duration: 2 });
    }
  }

  async function fetchAdverts(searchParams) {
    setLoading(true);

    storage.removeItem(`filters`);
    const advertItems = await getAdverts(searchParams);
    if (advertItems) {
      store.store.dispatch(updateAdverts(advertItems));

      if (pageLoaded.current) {
        window.scrollTo({ top: 0 });
      }
      pageLoaded.current = true;
    }
    setLoading(false);
  }
  useEffect(() => {
    if (
      location.search !== locationSearch.current ||
      location.pathname !== locationPathName.current ||
      firstLoad.current
    ) {
      firstLoad.current = false;
      setFiltersCollapsed(true);

      const searchParamsInUrlSnapshot = qs.parse(location.search, {
        ignoreQueryPrefix: true,
      });

      if (categoryIdRef.current !== categoryId) {
        searchParamsInUrlSnapshot.page = '1';
      }

      if (
        ['/horses-for-sale', '/horses-and-ponies-for-sale'].includes(location.pathname)
      ) {
        searchParamsInUrlSnapshot.saleTypeId = SALE;
      }

      if (['/horses-for-loan-share'].includes(location.pathname)) {
        searchParamsInUrlSnapshot.saleTypeId = LOAN;
      }

      const { urlParameters, apiQuery } = getSearchOptions(searchParamsInUrlSnapshot);

      updateUrl(urlParameters, true);
      fetchAdverts(apiQuery);

      categoryIdRef.current = categoryId;
      locationSearch.current = location.search;
      locationPathName.current = location.pathname;
    }
  }, [location]);

  let backLink = `${REACT_APP_CLASSIFIEDS_URL}`;

  if (typeof window !== 'undefined') {
    backLink = encodeURIComponent(window?.location?.href);
  }
  const filterBoxContent = (
    <>
      <FilterBox
        categoryId={categoryId}
        disciplines={disciplines}
        filters={filterBoxProps.filters}
        sort={filterBoxProps.sort}
        onSortChange={async (sort) => {
          setFiltersCollapsed(true);
          message.loading({ content: 'Loading...', key: messageKey });

          // setSearchParams(query);
          const { urlParameters, apiQuery } = getSearchOptions({
            ...filterBoxProps.filters,
            sort,
          });

          updateUrl(urlParameters, true);
          await fetchAdverts(apiQuery);
          message.success({ content: 'Filters Applied', key: messageKey, duration: 2 });
        }}
        onFilterChange={async (newParams) => {
          setFiltersCollapsed(true);
          message.loading({ content: 'Loading...', key: messageKey });

          const { urlParameters, apiQuery } = getSearchOptions({
            sort: filterBoxProps.sort,
            ...newParams,
          });

          updateUrl(urlParameters, true);
          await fetchAdverts(apiQuery);

          // setSearchParams(searchParamsDataValuesChanged.api);
          // updateUrl(searchParamsDataValuesChanged.urlParameters, true);

          // resetAdverts(searchParamsDataValuesChanged.api, 'Filters Applied');
          message.success({ content: 'Filters Applied', key: messageKey, duration: 2 });
        }}
        onClearFilters={() => {
          setFiltersCollapsed(true);
          message.loading({ content: 'Loading...', key: messageKey });

          const query = {
            filters: {
              categoryId,
            },
          };
          // setSearchParams(query);

          updateUrl({ ...query.filters, categoryId: undefined }, true);

          // history.push({
          //   pathname: `/${queryStringPassThrough(location.search)}`,
          // });

          resetAdverts(query, 'Filters Cleared');
        }}
      />
      <span style={{ marginTop: 16, textAlign: 'center', display: 'block' }}>
        Want sellers to come to you?
      </span>
      <Button
        block
        // size="large"
        style={{
          display: 'inline-block',
          margin: 0,
          marginTop: 12,
          color: '#ee5827',
          backgroundColor: 'white',
          border: '2px solid #ee5827',
        }}
        href={`${REACT_APP_WHICKR_URL}/create-wanted-advert?p=horseandhound&backLink=${backLink}`}
      >
        Place a wanted advert
      </Button>
    </>
  );

  const currentTotal = (fromItems, totalItems) => {
    let currentTotal = parseInt(fromItems);

    if (currentTotal > totalItems) {
      currentTotal = totalItems;
    }

    return currentTotal;
  };

  const pageMeta = {
    horse: {
      title: `Horses for sale, UK - Horse & Hound`,
      description:
        'Find Horses for sale, UK with Horse & Hound’s trusted equestrian marketplace',
    },
    job: {
      title: 'Equestrian jobs, UK - Horse & Hound',
      description:
        'Find Equestrian jobs, UK with Horse & Hound’s trusted equestrian marketplace',
    },
    property: {
      title: 'Equestrian properties, UK - Horse & Houndd',
      description:
        'Find Equestrian properties, UK with Horse & Hound’s trusted equestrian marketplace',
    },
    trailer: {
      title: 'Horseboxes & trailers, UK - Horse & Hound',
      description:
        'Find Horseboxes & trailers, UK with Horse & Hound’s trusted equestrian marketplace',
    },
    cat: {
      title: 'Cats, UK - Horse & Hound',
      description: 'Find Cats, UK with Horse & Hound’s trusted equestrian marketplace',
    },
    dog: {
      title: 'Dogs, UK - Horse & Hound',
      description: 'Find Dogs, UK with Horse & Hound’s trusted equestrian marketplace',
    },
    liveryYard: {
      title: 'Livery Yards, UK - Horse & Hound',
      description:
        'Find Livery yards, UK with Horse & Hound’s trusted equestrian marketplace',
    },
    misc: {
      title: 'Miscellaneous, UK - Horse & Hound',
      description:
        'Find Miscellaneous, UK with Horse & Hound’s trusted equestrian marketplace',
    },
    stallionsAtStud: {
      title: 'Stallions at Stud, UK - Horse & Hound',
      description:
        'Find Stallions at stud, UK with Horse & Hound’s trusted equestrian marketplace',
    },
  };

  return (
    <div id="browseContainer">
      <Head
        title={pageMeta[categoryId].title}
        description={pageMeta[categoryId].description}
      />
      {/* <div style={{ height: 32 }} /> */}
      <div className="container" style={{ marginTop: 24 }}>
        <Row gutter={[32, 16]}>
          <Col
            xs={{ span: 24, order: 2 }}
            sm={{ span: 24, order: 2 }}
            md={{ span: 24, order: 2 }}
            lg={{ span: 18, order: 2 }}
          >
            <Row gutter={[0, 32]} className="poweredByInfoBoxHolder">
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}
              >
                <div className="poweredByInfoBox">
                  <div className="poweredByInfoLogoHolder">
                    <img src="/img/logo-white-transparent-background.png" width="120" />
                  </div>
                  <div style={{ flex: 3 }}>
                    <Typography.Title
                      level={5}
                      style={{
                        marginBottom: 4,
                        // fontFamily: 'Merriweather',
                        color: 'white',
                      }}
                    >
                      Adverts powered by Whickr
                    </Typography.Title>
                    <Typography.Paragraph style={{ color: '#fff' }}>
                      Horse &amp; Hound has partnered with Whickr to give you the best
                      marketplace experience for buying and selling all things equestrian.
                    </Typography.Paragraph>

                    <a
                      href="https://www.horseandhound.co.uk/whickr"
                      style={{ color: '#0CCF87', marginTop: 16 }}
                    >
                      <Space>
                        Read more about our partnership with Whickr
                        <ArrowRightOutlined />
                      </Space>
                    </a>
                  </div>
                </div>
              </Col>
            </Row>
            <AdvertList staticAdverts={staticAdverts}>
              {({ adverts, from, total }) => (
                <>
                  <Row gutter={[0, 12]}>
                    <Col
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 24 }}
                      lg={{ span: 24 }}
                    >
                      <Typography.Title
                        level={3}
                        style={{ marginBottom: 0, fontFamily: 'Merriweather' }}
                      >
                        {categoryTitle[categoryId]}
                      </Typography.Title>
                      {!from ? (
                        <Typography.Paragraph style={{ color: '#6f6f6f' }}>
                          Searching...
                        </Typography.Paragraph>
                      ) : (
                        <Typography.Paragraph style={{ color: '#6f6f6f' }}>
                          Currently displaying {parseInt(from) - 9} -{' '}
                          {currentTotal(from, total)} of {total}
                        </Typography.Paragraph>
                      )}
                    </Col>
                  </Row>
                  <Row gutter={[0, 0]}>
                    <Col
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 24 }}
                      lg={{ span: 24 }}
                    >
                      {!loading && adverts.length === 0 && (
                        <div style={{ marginTop: 60 }}>
                          <Empty
                            image="/img/no-adverts.svg"
                            imageStyle={{
                              height: 40,
                              marginBottom: 16,
                            }}
                            description={
                              <span className="emptyMessage">
                                <h2>No Adverts</h2>
                                <p>Try expanding your search!</p>
                              </span>
                            }
                          />
                        </div>
                      )}
                      {adverts.length > 0 && (
                        <>
                          {adverts.map(
                            (
                              {
                                id,
                                slug,
                                price,
                                age,
                                headline,
                                description,
                                height,
                                formattedLocation,
                                seller,
                                favouriteId,
                                soldAt,
                                isPOA,
                                isFree,
                                media,
                                featuredAt,
                                youTubeVideos,
                                ...advert
                              }: Advert,
                              index
                            ) => {
                              const sellerId = seller?.id;
                              const firstName = seller?.firstName;
                              const lastName = seller?.lastName;
                              const isBusiness = seller?.isBusiness;
                              const businessName = seller?.businessName;

                              const gender = advert.gender?.label;
                              const type = advert.type?.label;
                              let saleType = advert.saleType?.label;
                              if (advert.categoryId === 'trailer') {
                                saleType = capitalizeFirstLetter(
                                  advert.trailerSaleTypeId
                                );
                              }

                              if (advert.categoryId === 'property') {
                                saleType = capitalizeFirstLetter(
                                  advert.propertySaleTypeId
                                );
                              }

                              return (
                                <div
                                  key={id}
                                  style={{ marginBottom: device.isMobile ? 24 : 48 }}
                                >
                                  <AdvertCard
                                    isFeatured={!!featuredAt}
                                    categoryId={categoryId}
                                    slug={slug}
                                    previewMedia={
                                      media && media.length > 0
                                        ? {
                                            ...media[0],
                                            url: `${getAmazonUrl(media[0].id)}${
                                              media[0].fileName
                                            }`,
                                          }
                                        : youTubeVideos &&
                                          youTubeVideos.length > 0 &&
                                          youTubeVideos[0]
                                        ? { ...youTubeVideos[0], type: 'youtube' }
                                        : null
                                    }
                                    autoPlayOnScroll
                                    price={price}
                                    title={headline}
                                    sellerName={
                                      isBusiness
                                        ? businessName
                                        : `${firstName} ${lastName}`
                                    }
                                    sellerLocation={formattedLocation}
                                    sellerId={sellerId}
                                    gender={gender}
                                    description={description}
                                    height={height}
                                    isPOA={isPOA}
                                    isFree={isFree}
                                    age={age}
                                    saleType={saleType}
                                    type={type}
                                    advertId={id}
                                    favouriteId={favouriteId}
                                    isSold={!!soldAt}
                                    user={user}
                                    size={device.isMobile ? 'small' : 'regular'}
                                  />
                                </div>
                              );
                            }
                          )}

                          <Pagination
                            current={
                              searchParamsInUrl.page
                                ? parseInt(searchParamsInUrl.page)
                                : 1
                            }
                            showSizeChanger={false}
                            pageSize={10}
                            total={total}
                            onChange={async (pageNumber) => {
                              setLoading(true);

                              message.loading({ content: 'Loading...', key: messageKey });

                              const searchParamsInUrlSnapshot = qs.parse(
                                location.search,
                                {
                                  ignoreQueryPrefix: true,
                                }
                              );

                              searchParamsInUrlSnapshot.page = pageNumber.toString();

                              const { urlParameters, apiQuery } = getSearchOptions(
                                searchParamsInUrlSnapshot
                              );

                              updateUrl(urlParameters, true);
                              await fetchAdverts(apiQuery);
                              setLoading(false);

                              window.scrollTo({ top: 0 });

                              message.destroy(messageKey);
                            }}
                          />
                        </>
                      )}

                      {loading && (
                        <div
                          style={{ textAlign: 'center', marginTop: 32, marginBottom: 32 }}
                        >
                          <Spin size="large" />
                        </div>
                      )}
                    </Col>
                  </Row>
                </>
              )}
            </AdvertList>
          </Col>
          <Col
            xs={{ span: 24, order: 1 }}
            sm={{ span: 24, order: 1 }}
            md={{ span: 24, order: 1 }}
            lg={{ span: 6, order: 1 }}
            className="filterBoxColumn"
          >
            <Collapse
              className="filterCollapseBox"
              activeKey={filtersCollapsed ? '' : 'sortFilter'}
              onChange={() => {
                setFiltersCollapsed(!filtersCollapsed);
              }}
            >
              <Panel header="Change Sort &amp; Filters" key="sortFilter">
                {filterBoxContent}
              </Panel>
            </Collapse>
            <div className="filterCollapseBoxDesktop">{filterBoxContent}</div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

BrowseScene.fetchData = async (ssrStore, match, req) => {
  try {
    const SSR = withSSRContext({ req });

    // ssrStore.dispatch(updateCategoryId(categoryIdPaths[match.path]));

    const searchParamsInUrl = qs.parse(req.query, {
      ignoreQueryPrefix: true,
    });

    const { urlParameters, apiQuery } = getSearchOptions(searchParamsInUrl, match.path);

    const advertItems = await getAdverts({
      ...apiQuery,
      amp: SSR,
    });

    if (advertItems) {
      ssrStore.dispatch(updateAdverts(advertItems));
    }
  } catch (e) {
    console.log('BrowseScene.fetchData error: ', JSON.stringify(e, null, 2));
  }
};

export default connect((state): { user: Profile } => ({
  user: state.user.id ? { ...state.user, loggedIn: true } : state.user,
  disciplines: state.misc.disciplines,
}))(BrowseScene);
