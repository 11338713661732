import React, { ReactElement, useEffect, useRef } from 'react';
import './styles.less';

import { Space, Typography } from 'antd';
import VizSensor from 'react-visibility-sensor';

import { Link, useLocation } from 'react-router-dom';
import YouTube from 'react-youtube';
import { CalendarOutlined, PushpinOutlined } from '@ant-design/icons';
import Card from '../Card';
import AdvertHeader from '../AdvertHeader';
import device from '../../utils/device';
import { categoryPathById } from '../../utils/categoryPaths';

const { REACT_APP_CLASSIFIEDS_URL, REACT_APP_WHICKR_URL } = process.env;

/**
 * Description
 */

type Props = {
  advertId: string;
  price?: string;
  sellerName: string | undefined;
  sellerLocation: string;
  title: string;
  imageSource?: string;
  size?: string;
  gender: string;
  age: number;
  height: number;
  saleType: string;
  favouriteId: string;
  isSold: boolean;
  isPOA: boolean;
  isFree: boolean;
  isFixedHeight?: boolean;
  showRemoveWarning?: boolean;
  youTubeVideo?: string;
  onFavouriteChanged?: (id: string, isFavourite: boolean) => void;
  mediaHeight?: number;
  categoryId?: string;
  publishedAt?: string;
  isFeatured?: boolean;
};

const defaultProps = {
  size: 'regular',
  price: '0',
  showRemoveWarning: false,
  onFavouriteChanged: () => {},
  user: { loggedIn: false },
  isFixedHeight: false,
  mediaHeight: null,
  categoryId: 'horse',
  publishedAt: null,
  isFeatured: false,
};

const AdvertCard: React.FC<Props> = ({
  advertId,
  slug,
  price,
  sellerName,
  sellerLocation,
  title,
  previewMedia,
  description,
  size,
  gender,
  age,
  height,
  saleType,
  favouriteId,
  isSold,
  isPOA,
  isFree,
  isFixedHeight,
  onFavouriteChanged,
  showRemoveWarning,
  user,
  autoPlayOnScroll,
  mediaHeight,
  type,
  sellerId,
  categoryId,
  publishedAt,
  inIframe,
  isFeatured,
}: Props): ReactElement => {
  const location = useLocation();

  useEffect(() => {
    if (previewMedia) {
      if (previewMedia.type === 'youtube') {
        setTimeout(() => {
          const offset = 0;
          const top = youTubeContainerRef?.current?.getBoundingClientRect().top;
          if (top + offset >= 0 && top - offset <= window.innerHeight) {
            youTubeRef?.current?.playVideo();
          }
        }, 1000);
      }
    }
  }, []);

  const youTubeRef = useRef();
  const youTubeContainerRef = useRef();
  const videoPlaying = useRef(false);
  let mediaStyles = {
    backgroundImage: 'url("/img/photo-placeholder.png")',
    backgroundSize: 'contain',
  };

  if (previewMedia && previewMedia.type === 'image') {
    mediaStyles = {
      backgroundImage: `url("${previewMedia.url || '/img/photo-placeholder.png'}")`,
      backgroundSize: previewMedia.url ? 'cover' : 'contain',
    };
  }

  let backLink = `${REACT_APP_CLASSIFIEDS_URL}/`;

  if (typeof window !== 'undefined') {
    backLink = encodeURIComponent(window?.location?.href);
  }

  let advertLink = `${REACT_APP_WHICKR_URL}/advert/${advertId}`;
  if (slug) {
    advertLink = `${REACT_APP_WHICKR_URL}/marketplace/${categoryPathById[categoryId]}/${slug}`;
  }

  const extra = {};

  if (inIframe) {
    extra.onClick = (e) => {
      e.preventDefault();
      parent.window.location.href = `${advertLink}?p=horseandhound&backLink=${backLink}`;
    };
  }
  const cardClasses = () => {
    if (isFeatured) {
      return 'advertCard featured';
    }
    return 'advertCard';
  };
  const cardComponent = (
    <Card style={{ height: '100%', borderRadius: isFeatured ? 16 : 8 }}>
      <div className={cardClasses()}>
        <a
          href={`${advertLink}?p=horseandhound&backLink=${backLink}`}
          className={
            size === 'small' ? 'smallAdvertCardContainer' : 'advertCardContainer'
          }
          rel="noreferrer"
          {...extra}
        >
          <div
            className={
              size === 'small' ? 'smallAdvertCardImageHolder' : 'advertCardImageHolder'
            }
            style={{
              ...mediaStyles,
              position: 'relative',
              paddingBottom: device.isMobile ? '100%' : 0,
            }}
          >
            {categoryId !== 'job' && (
              <div className="advertPriceInfoHolder">
                {isSold ? (
                  <div className="advertPrice sold">SOLD</div>
                ) : (
                  <div className="advertPrice">
                    {isPOA ? 'POA' : isFree || parseInt(price) === 0 ? 'Free' : price}
                  </div>
                )}
                {!isSold && saleType && <div className="advertSaleType">{saleType}</div>}
              </div>
            )}
            {previewMedia && previewMedia.type === 'video' && (
              <div
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                }}
              >
                <video
                  height="100%"
                  id={previewMedia.id}
                  muted
                  controls={false}
                  loop
                  playsInline
                  className="video"
                  style={{
                    borderTopLeftRadius: 4,
                    borderBottomLeftRadius: 4,
                    overflow: 'hidden',
                    height: '100%',
                  }}
                >
                  <source src={`${previewMedia.url}#t=0.1`} />
                  Your browser does not support the video tag.
                </video>
              </div>
            )}
            {previewMedia && previewMedia.type === 'youtube' && (
              <div
                style={{
                  borderTopLeftRadius: 8,
                  borderTopRightRadius: 8,
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  bottom: 0,
                  width: '100%',
                  height: '100%',
                  overflow: 'hidden',
                }}
                className="youtubeHolder"
                ref={youTubeContainerRef}
              >
                <YouTube
                  onReady={(e) => {
                    youTubeRef.current = e.target;
                  }}
                  videoId={previewMedia.id} // defaults -> null
                  opts={{
                    playerVars: { rel: 0, showinfo: 0, mute: 1, controls: 0 },
                    width: '100%',
                    height: '100%',
                  }}
                />
              </div>
            )}
          </div>
          <div className="advertInfoHolder">
            <div className="advertHeaderHolder">
              <Typography.Title
                level={4}
                style={{ fontFamily: 'Merriweather' }}
                className="advertTitle"
              >
                {title}
              </Typography.Title>
              <Typography.Paragraph className="advertDesc">
                {description}
              </Typography.Paragraph>
            </div>
            <div className="advertFooterHolder">
              <Space size="large" className="sellerInfoText" style={{ marginBottom: 4 }}>
                {publishedAt && (
                  <Space>
                    <CalendarOutlined />
                    <span>{publishedAt}</span>
                  </Space>
                )}
                {sellerLocation && (
                  <Space>
                    <PushpinOutlined />
                    <span>{sellerLocation}</span>
                  </Space>
                )}
              </Space>
              {age && (
                <ul className="advertFooterInfo">
                  {age && <li>{age?.toString()}yrs</li>}
                  {height && <li>{height.toString()}hh</li>}
                  {gender && <li>{gender}</li>}
                </ul>
              )}
              <div className="whickrLinkHolder">
                <Space>
                  <span className="viewText">View on Whickr</span>
                  <img src="/img/arrowRightIcon.svg" alt="View Advert" />
                </Space>
                {isFeatured && (
                  <span className="featureTag">
                    Featured {size !== 'small' && `Advert`}
                  </span>
                )}
              </div>
            </div>
          </div>
        </a>
      </div>
    </Card>
  );

  if (autoPlayOnScroll) {
    return (
      <VizSensor
        partialVisibility
        offset={{ top: 220, bottom: 260 }}
        onChange={(isVisible) => {
          if (previewMedia) {
            if (previewMedia.type === 'youtube') {
              if (isVisible) {
                if (youTubeRef.current && !videoPlaying.current) {
                  videoPlaying.current = true;
                  youTubeRef.current.seekTo(0);
                  youTubeRef.current.playVideo();
                }
              } else if (youTubeRef.current && videoPlaying.current) {
                videoPlaying.current = false;
                youTubeRef.current.pauseVideo();
              }
            }

            if (previewMedia.type === 'video') {
              if (isVisible) {
                if (!videoPlaying.current) {
                  videoPlaying.current = true;
                  if (document) {
                    document.getElementById(previewMedia.id).currentTime = 0;
                    document.getElementById(previewMedia.id)?.play();
                  }
                }
              } else if (videoPlaying.current) {
                videoPlaying.current = false;
                if (document) {
                  document.getElementById(previewMedia.id)?.pause();
                  document.getElementById(previewMedia.id).currentTime = 0;
                }
              }
            }
          }
        }}
      >
        {cardComponent}
      </VizSensor>
    );
  }

  return cardComponent;
};

AdvertCard.defaultProps = defaultProps;
export default AdvertCard;
